import { CONFIG } from "src/config";

export const NAVIGATIONS = {
  root: {
    url: '',
    title: CONFIG.title,
    description: CONFIG.title + 'provide open source apps, games and responsive websites',
  },
  apps: {
    url: 'apps',
    title: 'Apps - ' + CONFIG.title,
    description: 'Apps - ' + CONFIG.title + ' provide multiple open source apps and games',
  },
  contactUs: {
    url: 'contact',
    title: 'Contact Us - ' + CONFIG.title,
    description: 'Contact Us - ' + CONFIG.title + ' provide multiple open source apps and games',
  },
  socialMedia: {
    url: 'social-media',
    title: 'Social Media - ' + CONFIG.title,
    description: 'Social Media - ' + CONFIG.title + ' provide multiple open source apps and games',
  },
  privacyPolicy: {
    url: 'privacy-policy',
    title: 'Privacy Policy - ' + CONFIG.title,
    description: 'Privacy Policy - ' + CONFIG.title + ' provide multiple open source apps and games',
  }
};

export const URLS = {
  // Landing layout
  root: '/',
  apps: '/' + NAVIGATIONS.apps.url,
  contact: '/' + NAVIGATIONS.contactUs.url,
  social: '/' + NAVIGATIONS.socialMedia.url,
  privacyPolicy: '/' + NAVIGATIONS.privacyPolicy.url,
};

export const NAV_MENUS = [
  {
    title: 'About',
    url: '/',
    i: 'fa-user'
  },
  {
    title: 'Apps',
    url: '/apps',
    i: 'fa-shapes'
  },
  {
    title: 'Contact',
    url: '/contact',
    i: 'fa-handshake'
  }
]