<!-- About Us -->
<div id="home" class="home-us-wrapper">
  <img firebaseImgLoader="assets/icons/wave.svg">
  <p>
    {{ CONFIG.description }}
  </p>
</div>

<!-- Apps -->
<div id="apps" class="products-wrapper">
  <app-title title="Apps"></app-title>
  <app-product-swiper *ngIf="apps && apps.length" [apps]="apps"></app-product-swiper>
</div>

<!-- Contact Us -->
<div id="contact" class="contact-us-wrapper">
  <app-contact-us></app-contact-us>
</div>
