import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { App } from 'src/app/model/app.model';
import { FirebaseService } from 'src/app/service/firebase.service';
import { URLS } from '../../../constants/navigation-constants';
import { CONFIG } from 'src/config';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})

export class HomePageComponent {

  public URLS = URLS;
  public CONFIG = CONFIG;
  public apps: App[] = [];

  constructor(
    public router: Router,
    public firebaseService: FirebaseService
  ) {
    // fetch apps from firebase db
    this.fetchApps();
    // check the current route to call the scrollIntoView
    this.handleNavigation(this.router.url);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.handleNavigation(event.url);
    });
  }

  private async fetchApps() {
    this.apps = await this.firebaseService.getApps();
  }

  private handleNavigation(routerUrl: string): void {
    if (Object.values(URLS).includes(routerUrl)) {
      this.scrollIntoView(routerUrl.substring(1));
    }
  }

  public async scrollIntoView(id: string) {
    // give some time to load element then scroll
    if (id) {
      setTimeout(() => {
        const el = document.getElementById(id);
        el.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 10);
    }
  }
}
